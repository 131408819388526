let linkConfig = {
  download: {
    ios: {
      eetrade: "https://apps.apple.com/cn/app/id6465077365",
    },
    androidUrl: {
      eetrade: "https://downloads.btgwxz8.com/source/material/EET_GW.apk",
    },
  },
};

export { linkConfig };
