import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Download from "pages/download";
import notFound from "pages/notFound";

export default () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Download} />
      <Route path="*" component={notFound} />
    </Switch>
  </Router>
);
